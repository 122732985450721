import createTheme from '@mui/material/styles/createTheme';
import responsiveFontSizes from '@mui/material/styles/responsiveFontSizes';

const baseTheme = createTheme({
    palette: {
      alternate: {
        main: '#e7f3f6',
        dark: '#edf1f7',
      },
      cardShadow: 'rgba(23, 70, 161, .11)',
      mode: 'light',
      primary: {
        main: '#09576a',
        light: '#0e83a0',
        dark: '#053440',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ffb74d',
        main: '#f9b934',
        dark: '#FF9800',
        contrastText: 'rgba(0, 0, 0, 0.87)',
      },
      text: {
        primary: '#1e2022',
        secondary: '#677788',
      },
      divider: 'rgba(0, 0, 0, 0.12)',
      background: {
        paper: '#ffffff',
        default: '#ffffff',
        level2: '#f5f5f5',
        level1: '#ffffff',
      },
    },
    shadows: [
      'none',
      `0 3px 6px 0 rgba(140, 152, 164, 0.25)`,
      `0 12px 15px rgba(140, 152, 164, 0.1)`,
      `0 6px 24px 0 rgba(140, 152, 164, 0.125)`,
      `0 10px 40px 10px rgba(140, 152, 164, 0.175)`,
      `0 10px 40px 10px rgba(140, 152, 164, 0.175)`,
      `0 10px 40px 10px rgba(140, 152, 164, 0.175)`,
      `0 10px 40px 10px rgba(140, 152, 164, 0.175)`,
      `0 10px 40px 10px rgba(140, 152, 164, 0.175)`,
      `0 10px 40px 10px rgba(140, 152, 164, 0.175)`,
      `0 10px 40px 10px rgba(140, 152, 164, 0.175)`,
      `0 10px 40px 10px rgba(140, 152, 164, 0.175)`,
      `0 10px 40px 10px rgba(140, 152, 164, 0.175)`,
      `0 10px 40px 10px rgba(140, 152, 164, 0.175)`,
      `0 10px 40px 10px rgba(140, 152, 164, 0.175)`,
      `0 10px 40px 10px rgba(140, 152, 164, 0.175)`,
      `0 10px 40px 10px rgba(140, 152, 164, 0.175)`,
      `0 10px 40px 10px rgba(140, 152, 164, 0.175)`,
      `0 10px 40px 10px rgba(140, 152, 164, 0.175)`,
      `0 10px 40px 10px rgba(140, 152, 164, 0.175)`,
      `0 10px 40px 10px rgba(140, 152, 164, 0.175)`,
      `0 10px 40px 10px rgba(140, 152, 164, 0.175)`,
      `0 10px 40px 10px rgba(140, 152, 164, 0.175)`,
      `0 10px 40px 10px rgba(140, 152, 164, 0.175)`,
      `0 10px 40px 10px rgba(140, 152, 164, 0.175)`,
    ],
    zIndex: {
      appBar: 1200,
      drawer: 1300,
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: 700,
            borderRadius: 5,
            paddingTop: 10,
            paddingBottom: 10,
          },
          containedSecondary: { color: 'white' },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            borderRadius: 5,
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 5,
          },
          input: {
            borderRadius: 5,
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: 8,
          },
        },
      },
    },
  })

const theme = responsiveFontSizes(baseTheme);

export default theme;
