import React from 'react';
import PropTypes from 'prop-types';
// import { ThemeProvider, Paper, CssBaseline } from '@mui/material';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import Paper from '@mui/material/Paper';
import CssBaseline from '@mui/material/CssBaseline';

import theme from 'theme';

export default function Page({ children }) {

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Paper elevation={0}>{children}</Paper>
    </ThemeProvider>
  );
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
};
