exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-categories-js": () => import("./../../../src/pages/categories.js" /* webpackChunkName: "component---src-pages-categories-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-termsofuse-js": () => import("./../../../src/pages/termsofuse.js" /* webpackChunkName: "component---src-pages-termsofuse-js" */),
  "component---src-templates-sitepage-maincat-js": () => import("./../../../src/templates/sitepage-maincat.js" /* webpackChunkName: "component---src-templates-sitepage-maincat-js" */),
  "component---src-templates-sitepage-product-js": () => import("./../../../src/templates/sitepage-product.js" /* webpackChunkName: "component---src-templates-sitepage-product-js" */),
  "component---src-templates-sitepage-qkwd-js": () => import("./../../../src/templates/sitepage-qkwd.js" /* webpackChunkName: "component---src-templates-sitepage-qkwd-js" */),
  "component---src-templates-sitepage-subcat-js": () => import("./../../../src/templates/sitepage-subcat.js" /* webpackChunkName: "component---src-templates-sitepage-subcat-js" */)
}

