import React from 'react';
import PropTypes from 'prop-types';
import Page from '../../src/components/Page';

export default function TopLayout(props) {
  return (
    <React.Fragment>
      <Page>
        {props.children}
      </Page>
    </React.Fragment>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node,
};
